export enum AuthActionTypes {
  LOGIN_AUTH = "LOGIN_AUTH",
  LOAD_INITIAL_DATA = "LOAD_INITIAL_DATA",
  REGISTER_AUTH = "REGISTER_AUTH",
  UPDATE_USER = "UPDATE_USER",
  DELETE_USER = "DELETE_USER",
  REPORT_USER = "REPORT_USER",
  BLOCK_USER = "BLOCK_USER",
  UNBLOCK_USER = "UNBLOCK_USER",
  ACCEPT_RINGING_CALL = "ACCEPT_RINGING_CALL",
  DECLINE_RINGING_CALL = "DECLINE_RINGING_CALL",
}
