export enum AppMutationTypes {
  SHOW_POPUP = "SHOW_POPUP",
  HIDE_POPUP = "HIDE_POPUP",

  SET_FULL_SIZE_IMAGE = "SET_FULL_SIZE_IMAGE",

  SET_LAYOUT_TYPE = "SET_LAYOUT_TYPE",
  SET_LAYOUT_WIDTH = "SET_LAYOUT_WIDTH",
  SET_LAYOUT_HEIGHT = "SET_LAYOUT_HEIGHT",
  SET_LAYOUT_BACKGROUND_COLOR_TOP = "SET_LAYOUT_BACKGROUND_COLOR_TOP",
  SET_LAYOUT_BACKGROUND_COLOR_BOTTOM = "SET_LAYOUT_BACKGROUND_COLOR_BOTTOM",
  SET_LAYOUT = "SET_LAYOUT",
  SET_LAYOUT_MESSAGE_INPUT_HEIGHT = "SET_LAYOUT_MESSAGE_INPUT_HEIGHT",

  TOGGLE_CURRENT_CHAT_SELECTED_MESSAGE = "TOGGLE_CURRENT_CHAT_SELECTED_MESSAGE",
  SET_CURRENT_CHAT_DELETING_MESSAGES = "SET_CURRENT_CHAT_DELETING_MESSAGES",
  REMOVE_ALL_CURRENT_CHAT_SELECTED_MESSAGES = "REMOVE_ALL_CURRENT_CHAT_SELECTED_MESSAGES",

  SET_LANG = "SET_LANG",

  SET_NOTIFICATIONS_STATUS = "SET_NOTIFICATIONS_STATUS",
  SET_PHONE_CONTACTS_STATUS = "SET_PHONE_CONTACTS_STATUS",

  SHOW_SNACKBAR = "SHOW_SNACKBAR",
  HIDE_SNACKBAR = "HIDE_SNACKBAR",
  SET_SNACKBAR_MOUNTED = "SET_SNACKBAR_MOUNTED",
  SET_SNACKBAR_UNMOUNTED = "SET_SNACKBAR_UNMOUNTED",

  SET_IS_LOADING = "SET_IS_LOADING",
  SET_ONBOARDING_ROUTES = "SET_ONBOARDING_ROUTES",
  SET_APP_DETAILS = "SET_APP_DETAILS",
}
