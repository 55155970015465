import i18n from "@/i18n";
import { User } from "@/store/users/state";

export const getSenderName = (
  senderId: number,
  authId: number,
  members: User[],
): string => {
  if (senderId === authId) {
    return i18n.global.t("user.i");
  }
  return members.find((member) => member?.id === senderId)?.name || "";
};
