export enum BridgeActionEnum {
  ChangeDisplayMode = "changeDisplayMode",
  OpenBrowser = "openBrowser",
  GetContacts = "getContacts",
  GetContactsStatus = "getContactsStatus",
  EnableBack = "enableBack",
  DisableBack = "disableBack",
  EnableScroll = "enableScroll",
  DisableScroll = "disableScroll",
  Login = "login",
  Logout = "logout",
  EnableNotifications = "enableNotifications",
  GetToken = "getToken",
  GetNotificationsStatus = "getNotificationsStatus",
  ComposePhoneMessage = "composePhoneMessage",
  GetAppDetails = "getAppDetails",
  SetLastSeenAppVersion = "setLastSeenAppVersion",
  SendEmail = "sendEmail",
  StartCall = "startCall",
  UpdateUser = "updateUser",
  AddChatsShareSuggestions = "addChatsShareSuggestions",
}

export enum BridgeDisplayModeEnum {
  Container = "container",
  NoSafeArea = "noSafeArea",
}

export enum BridgeStatusEnum {
  Unused = "unused",
  Enabled = "enabled",
  Disabled = "disabled",
}

export enum BridgeActionTypeEnum {
  Contacts = "contacts",
  NotificationsStatus = "notificationsStatus",
  ContactsStatus = "contactsStatus",
  AppDetails = "appDetails",
  ShareAndroid = "shareAndroid",
  ShareImagesAndroid = "shareImagesAndroid",
}

export interface BridgeMessageActionObject {
  action: BridgeActionEnum;
  displayMode?: string;
  topColor?: string;
  bottomColor?: string;
  refreshToken?: string;
  lang?: string;
  phoneNumber?: string;
  messageBody?: string;
  lastSeenAppVersion?: string;
  recipient?: string;
  subject?: string;

  userId?: string;
  userName?: string;
  userPictureUrl?: string;

  // start video call
  calleeDisplayName?: string;
  twilioAccessToken?: string;
  chatId?: string;
  callType?: CallType;
  chatPictureUrl?: string;

  // user suggestions for sharing through chat app
  userSuggestions?: string;
}

export enum CallType {
  Audio = "audio",
  Video = "video",
}

export interface BridgeMessageAppDetailsResponseObject {
  lastSeenAppVersion: string;
  currentAppVersion: string;
  deviceModelName: string;
  osVersion: string;
  regionCode: string;
}
