import { Profile } from "@/store/auth/state";
import {
  RawTextMessage,
  RawChat,
  GroupRawChat,
  Message,
  RawAudioMessage,
} from "@/store/chats/types";
import { convertRawGroupTextMessage } from "./convertRawGroupTextMessage";
import { convertRawOneToOneTextMessage } from "./convertRawOneToOneTextMessage";
import { getIsOneToOneRawTextMessage } from "./getIsOneToOneRawTextMessage";
import { User } from "@/store/users/state";
import { convertRawOneToOneAudioMessage } from "./convertRawOneToOneAudioMessage";
import { getIsOneToOneRawAudioMessage } from "./getIsOneToOneRawAudioMessage";
import { convertRawGroupAudioMessage } from "./convertRawGroupAudioMessage";

interface ConvertRawAudioMessageArgs {
  rawMessage: RawAudioMessage;
  rawChat: RawChat;
  auth: Profile;
  users?: User[];
}

export const convertRawAudioMessage = ({
  rawMessage,
  rawChat,
  auth,
  users,
}: ConvertRawAudioMessageArgs): Message => {
  if (getIsOneToOneRawAudioMessage(rawMessage, rawChat.id)) {
    return convertRawOneToOneAudioMessage({
      rawMessage,
      auth,
      users,
      chatId: rawChat.id,
    });
  }

  const groupChat = rawChat as GroupRawChat;

  return convertRawGroupAudioMessage({
    rawMessage,
    auth,
    users,
    memberIds: groupChat.members,
  });
};
