import { useComputedValue } from "@/composables";
import { GetterTypes, MutationTypes, ActionTypes } from "@/store";
import { LayoutTypes } from "@/store/app/state";
import { Attachment, ChatTypes, MessageType } from "@/store/chats/types";
import { isAppEnvironmentContainer } from "@/utils/app";
import { convertFilesToAttachments } from "@/utils/attachment";
import { ref, ComponentPublicInstance, reactive, computed, watch } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { ChatInputBarData, ChatInputBarProps } from "./ChatInputBarTypes";
import InputText from "../InputText/InputText.vue";
import { createCachedMessage } from "@/utils/message";
import { useAudioPlayer } from "../AudioPlayer";
const containerId = "audioPlayer";

export const useGetChatInputBarProperties = (props: ChatInputBarProps) => {
  const { commit, dispatch } = useStore();
  const router = useRouter();
  const messageInput = ref<ComponentPublicInstance<typeof InputText>>();
  const data = reactive<ChatInputBarData>({
    messageText: "",
    attachments: [],
    isEmojiShown: false,
  });

  const chatId = useComputedValue<string>(GetterTypes.GET_SELECTED_CHAT_ID);
  const layoutType = useComputedValue<LayoutTypes>(GetterTypes.GET_LAYOUT_TYPE);
  const sharedMessage = useComputedValue<string | null>(
    GetterTypes.GET_SHARED_MESSAGE,
  );
  const memberIds = computed(
    () => props.members?.map((member) => String(member.id)) ?? [],
  );
  const messageType = computed(() => {
    if (
      recordingData.isRecording ||
      (recordingData.isFinished && recordingData.recordingFile)
    ) {
      return MessageType.Audio;
    }
    return MessageType.Text;
  });

  const isAudioMessageInQueue = ref<boolean>(false);

  const getAppliedAttachments = async () => {
    if (messageType.value === MessageType.Audio) {
      const files = [recordingData.recordingFile].filter(Boolean) as File[];
      const attachments = await convertFilesToAttachments(files);
      return attachments.map((att) => ({
        ...att,
        duration: recordingData.totalTime,
      }));
    }
    return data.attachments;
  };

  const {
    stopRecording,
    recordingData,
    startRecording,
    clearRecording,
    audioPlayerProps,
    isRecordingOrFinished,
  } = useAudioPlayer({
    isRecorder: true,
    isMine: true,
    containerId,
  });

  watch(
    sharedMessage,
    () => {
      if (sharedMessage.value) {
        data.messageText = sharedMessage.value;
        commit(MutationTypes.SET_SHARED_MESSAGE, "");
      }
    },
    { immediate: true },
  );

  const messageInputHeight = useComputedValue(
    GetterTypes.GET_LAYOUT_MESSAGE_INPUT_HEIGHT,
  );

  const isInputShown = computed<boolean>(() => {
    if (
      isAppEnvironmentContainer &&
      chatId.value === ChatTypes.NEW_CHAT_TYPE &&
      !props.members?.length
    ) {
      return false;
    }
    return true;
  });
  const isMobile = computed<boolean>(
    () => layoutType.value === LayoutTypes.MOBILE,
  );
  const trimmedMessageText = computed<string>(() => data.messageText.trim());

  const toggleShowEmoji = () => {
    focusMessageInput();
    data.isEmojiShown = !data.isEmojiShown;
  };

  const sendMessageAndClear = async () => {
    if (recordingData.isRecording) {
      await stopRecording();

      // This marks the audio message as in a queue that will be later sent via a side effect connected to recordingData.recordingFile.
      isAudioMessageInQueue.value = true;
      return;
    }

    await handleSendMessage();

    resetInputs();
    focusMessageInput();
  };

  // this sends out the audio message that is in the queue and clears it from the audio input
  watch(
    () => recordingData.recordingFile,
    async (file) => {
      if (!file || !isAudioMessageInQueue.value) {
        return;
      }
      await handleSendMessage();
    },
  );

  const handleSendMessage = async () => {
    const appliedAttachments = await getAppliedAttachments();
    if (
      (!trimmedMessageText.value && appliedAttachments.length === 0) ||
      (!props.members?.length && chatId.value === ChatTypes.NEW_CHAT_TYPE)
    ) {
      return;
    }

    if (props.newChat) {
      sendNewChatMessage(appliedAttachments);
      return;
    }

    sendMessageToChat(chatId.value, appliedAttachments);
    isAudioMessageInQueue.value = false;
    clearRecording();
  };

  const focusMessageInput = () => {
    const inputNode = messageInput.value?.$el;
    if (!inputNode) {
      return;
    }
    inputNode.focus({ preventScroll: true });
  };

  const sendNewChatMessage = async (attachments: Attachment[]) => {
    if (props.alreadyExistsChatId) {
      await sendMessageToChat(props.alreadyExistsChatId, attachments);
      commit(MutationTypes.SET_SELECTED_CHAT, {
        chatId: props.alreadyExistsChatId,
      });
      router.push(`/${props.alreadyExistsChatId}`);
      return;
    }

    const chatId = await dispatch(ActionTypes.CREATE_CHAT_WITH_MESSAGE, {
      messageText: trimmedMessageText.value,
      attachments,
      memberIds: memberIds.value,
      members: props.members,
      messageType: messageType.value,
    });

    router.push(`/${chatId}`);
  };

  const sendMessageToChat = async (
    chatId: string,
    attachments: Attachment[],
  ) => {
    const cachedMessage = createCachedMessage(memberIds.value, {
      messageText: trimmedMessageText.value,
      attachments,
      chatId,
      messageType: messageType.value,
    });

    try {
      await dispatch(ActionTypes.SEND_MESSAGE, {
        chatId,
        message: cachedMessage,
        messageType: messageType.value,
      });
    } catch (e) {
      console.log(e);
    }
  };

  const resetInputs = () => {
    data.isEmojiShown = false;
    data.messageText = "";
    data.attachments = [];
  };

  const addMessageText = (text: string) => {
    data.messageText += text;
    focusMessageInput();
  };

  const setMessageText = (text: string) => {
    data.messageText = text;
  };

  const addAttachment = async (files: FileList) => {
    data.attachments = [
      ...data.attachments,
      ...(await convertFilesToAttachments(Array.from(files))),
    ];
  };

  const removeAttachment = (id: string | undefined) => {
    data.attachments = data.attachments.filter((att) => att.id !== id);
  };

  return {
    data,
    isInputShown,
    isMobile,
    toggleShowEmoji,
    addAttachment,
    addMessageText,
    focusMessageInput,
    messageInputHeight,
    removeAttachment,
    setMessageText,
    sendMessage: sendMessageAndClear,
    messageInput,

    startRecording,
    clearRecording,
    audioPlayerProps,
    isRecordingOrFinished,
  };
};
