<template>
  <div v-if="show" v-click-outside="hideEmoji" class="emoji-button">
    <div class="emoji-card-wrapper">
      <div class="emoji-card">
        <span
          v-for="(emoji, i) in EMOJI_ARRAY"
          :key="i"
          @click="insertEmoji(emoji)"
        >
          {{ emoji }}
        </span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { EMOJI_ARRAY } from "@/data/emoji";

interface Props {
  show: boolean;
}
interface Emits {
  (e: "insert-emoji", emoji: string): void;
  (e: "toggle-emoji-list-visibility"): void;
}

withDefaults(defineProps<Props>(), {
  show: false,
});
const emit = defineEmits<Emits>();

const hideEmoji = (e: MouseEvent) => {
  const target = e.target as HTMLElement;
  if (target.classList.contains("emoji-btn")) {
    return;
  }
  emit("toggle-emoji-list-visibility");
};
const insertEmoji = (emoji: string) => {
  emit("insert-emoji", emoji);
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/cards/emojiCard.scss";
</style>
