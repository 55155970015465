import { AuthActionTypes } from "./actionTypes";
import { AuthMutationTypes } from "./mutationTypes";
import { AuthActionType } from "./actionState";
import { getRefreshToken, getLangFromRefreshToken } from "@/utils/api";
import {
  blockUserAsync,
  createNewUserAsync,
  deleteUserAsync,
  logoutUser,
  reportUserAsync,
  unblockUserAsync,
  updateUserAsync,
} from "@/services/maitrejaApi/auth";
import { UsersActionTypes } from "@/store/users";
import { AppMutationTypes } from "../app";
import { signToFirebaseAsync } from "../../firebase/auth";
import { auth } from "../../firebase/firebase";
import { ActionTypes, MutationTypes } from "..";
import { Chat, ChatBlockingStatusEnum, RawChat } from "../chats/types";
import i18n from "@/i18n";
import { getLanguage } from "@/utils/api";
import { getUserRef } from "@/firebase/ref";
import { getInitialUsersAndChatsAsync } from "@/utils/chat";
import { getIsUserBlockedAsync } from "@/utils/auth";
import { User } from "../users/state";
import { Profile } from "./state";
import { declineRingingCallAsync } from "@/services";
import { AuthGetterTypes } from "./getters";
import {
  IOngoingCall,
  FirestoreRingingCall,
  FirestoreUserProfile,
} from "./types";
import { CallType } from "@/types/webview";
import { ChatsGetterTypes } from "../chats";

export const authActions: AuthActionType = {
  async [AuthActionTypes.LOGIN_AUTH]({ commit, dispatch, getters }) {
    const refreshToken = getRefreshToken();
    const lang = getLanguage();
    if (!refreshToken && lang) {
      i18n.global.locale.value = lang;
      // @ts-expect-error
      commit(AppMutationTypes.SET_LANG, lang);
    }

    try {
      if (!refreshToken) throw new Error("Refresh token is not defined");
      commit(AuthMutationTypes.SET_AUTH_REFRESH_TOKEN, refreshToken);

      const { accessToken } = await signToFirebaseAsync(refreshToken);
      const firebaseIdToken = await auth.currentUser?.getIdToken(true);
      const lang = getLangFromRefreshToken(refreshToken);

      const isLoading = getters.GET_IS_LOADING;
      if (isLoading) {
        i18n.global.locale.value = lang;
      }
      // @ts-expect-error
      commit(AppMutationTypes.SET_LANG, lang);
      commit(AuthMutationTypes.SET_AUTH_ACCESS_TOKEN, accessToken);
      commit(AuthMutationTypes.SET_AUTH_FIREBASE_ID_TOKEN, firebaseIdToken);

      dispatch(AuthActionTypes.LOAD_INITIAL_DATA, accessToken);
      dispatch(UsersActionTypes.FETCH_AUTH_FRIENDS, accessToken);

      // @ts-expect-error
    } catch (err: Error) {
      console.log(err.message);
      const lang = getters.GET_LANGUAGE;
      i18n.global.locale.value = lang;
      throw err;
    }
  },

  async [AuthActionTypes.REGISTER_AUTH](
    { commit, dispatch, getters },
    { name, email, password },
  ) {
    try {
      const data = await createNewUserAsync(name, email, password);
      if (!data.refresh_token) throw new Error("Registration failed");
      commit(AuthMutationTypes.SET_AUTH_REFRESH_TOKEN, data.refresh_token);

      const lang: string = getLangFromRefreshToken(data.refresh_token);
      const { accessToken } = await signToFirebaseAsync(data.refresh_token);
      const firebaseIdToken = await auth.currentUser?.getIdToken(true);

      const isLoading = getters.GET_IS_LOADING;
      if (isLoading) {
        i18n.global.locale.value = lang;
      }
      // @ts-expect-error
      commit(AppMutationTypes.SET_LANG, lang);
      commit(AuthMutationTypes.SET_AUTH_ACCESS_TOKEN, accessToken);
      commit(AuthMutationTypes.SET_AUTH_FIREBASE_ID_TOKEN, firebaseIdToken);

      dispatch(AuthActionTypes.LOAD_INITIAL_DATA);
      dispatch(UsersActionTypes.FETCH_AUTH_FRIENDS, accessToken);

      // @ts-expect-error
    } catch (err: Error) {
      console.log(err);
      throw err;
    }
  },

  async [AuthActionTypes.LOAD_INITIAL_DATA]({
    commit,
    dispatch,
    getters,
    rootState,
  }) {
    try {
      const accessToken = getters.GET_AUTH_ACCESS_TOKEN;
      const authId = rootState.auth.profile?.id;
      if (!accessToken) return;

      const authRef = await getUserRef(authId).get();
      const data = authRef.data() as FirestoreUserProfile;
      const allChatIds: string[] = data?.chats || [];
      const { users, chats, remainingChatIds } =
        await getInitialUsersAndChatsAsync(allChatIds, accessToken);

      // @ts-expect-error
      commit(MutationTypes.SET_ALL_CHAT_IDS, {
        allChatIds,
        remainingChatIds,
      });
      // @ts-expect-error
      commit(MutationTypes.SET_USERS, users);
      // @ts-expect-error
      commit(MutationTypes.SET_CHATS, chats);
      dispatch(ActionTypes.ADD_AUTH_CHATS_LISTENER);

      // @ts-expect-error
    } catch (err: Error) {
      console.log(err.message);
    }
  },
  async [AuthActionTypes.UPDATE_USER](
    { commit, getters },
    { name, photo, email, password },
  ) {
    try {
      const accessToken = getters.GET_AUTH_ACCESS_TOKEN;
      const profile: Profile = getters.GET_AUTH_PROFILE;
      if (!accessToken) return;
      await updateUserAsync(accessToken, { name, photo, email, password });
      const newProfile: Pick<Profile, "name" | "picture" | "email"> = {
        name,
        picture: photo,
        email: profile.email,
      };
      if (email) {
        newProfile.email = email;
      }

      commit(AuthMutationTypes.UPDATE_AUTH_PROFILE, newProfile);
    } catch (err) {
      console.log(err);
    }
  },
  async [AuthActionTypes.DELETE_USER]({ dispatch, getters }) {
    try {
      const accessToken = getters.GET_AUTH_ACCESS_TOKEN;
      if (!accessToken) return;

      await deleteUserAsync(accessToken);

      dispatch(ActionTypes.MOUNT_SNACKBAR, {
        title: "snackbar.notifications.yourAccountWasDeleted.title",
        text: "snackbar.notifications.yourAccountWasDeleted.text",
      });

      setTimeout(() => {
        logoutUser();
      }, 3000);

      // @ts-expect-error
    } catch (err: Error) {
      console.log(err.message);
    }
  },
  async [AuthActionTypes.REPORT_USER]({ dispatch, getters }, partnerId) {
    try {
      const accessToken = getters.GET_AUTH_ACCESS_TOKEN;
      if (!accessToken) return;

      await reportUserAsync(accessToken, partnerId);

      dispatch(ActionTypes.MOUNT_SNACKBAR, {
        title: "snackbar.notifications.thankYouForReportingUser.title",
        text: "snackbar.notifications.thankYouForReportingUser.text",
      });

      // @ts-expect-error
    } catch (err: Error) {
      console.log(err.message);
    }
  },
  async [AuthActionTypes.BLOCK_USER]({ commit, dispatch, getters }, partnerId) {
    try {
      const accessToken = getters.GET_AUTH_ACCESS_TOKEN;
      if (!accessToken) return;

      await blockUserAsync(accessToken, partnerId);
      const user: User = getters.GET_USER(partnerId);
      // @ts-expect-error
      commit(MutationTypes.UPDATE_USER, {
        ...user,
        blockingStatus: ChatBlockingStatusEnum.Me,
      });
      dispatch(ActionTypes.MOUNT_SNACKBAR, {
        title: "snackbar.notifications.theUserWasBlocked.title",
        text: "snackbar.notifications.theUserWasBlocked.text",
      });

      // @ts-expect-error
    } catch (err: Error) {
      console.log(err.message);
    }
  },
  async [AuthActionTypes.UNBLOCK_USER](
    { dispatch, commit, getters },
    partnerId,
  ) {
    try {
      const accessToken = getters.GET_AUTH_ACCESS_TOKEN;
      if (!accessToken) return;
      await unblockUserAsync(accessToken, partnerId);
      const user: User = getters.GET_USER(partnerId);
      const blockingStatus = await getIsUserBlockedAsync(partnerId);
      // @ts-expect-error
      commit(MutationTypes.UPDATE_USER, { ...user, blockingStatus });
      dispatch(ActionTypes.MOUNT_SNACKBAR, {
        title: "snackbar.notifications.theUserWasUnblocked.title",
      });

      // @ts-expect-error
    } catch (err: Error) {
      console.log(err.message);
    }
  },
  async [AuthActionTypes.ACCEPT_RINGING_CALL]({ commit, getters }, chatId) {
    // optimistic update - on backend this is done via webhook
    const ringingCalls: FirestoreRingingCall[] =
      getters[AuthGetterTypes.GET_RINGING_CALLS];
    const acceptedCall = ringingCalls.find((call) => call.chatId === chatId);
    if (!acceptedCall) {
      return;
    }
    const rawChat: RawChat = getters[ChatsGetterTypes.GET_RAW_CHAT](
      acceptedCall.chatId,
    );
    const chat: Chat = getters[ChatsGetterTypes.GET_CONVERTED_CHAT_DETAIL](
      acceptedCall.chatId,
    );
    const callType = rawChat.pendingCallType ?? CallType.Video;
    const hasVideo = callType === CallType.Video;
    const newOngoingCall: IOngoingCall = {
      ...acceptedCall,
      hasVideo,
      hasAudio: true,
      callType,
      displayChatName: chat.name,
      displayChatPicture: chat.picture,
    };
    commit(AuthMutationTypes.ADD_ONGOING_CALL, newOngoingCall);
    commit(AuthMutationTypes.SET_CURRENT_ONGOING_CALL_ID, chatId);
    // optimistic update
    commit(AuthMutationTypes.REMOVE_RINGING_CALL, chatId);
  },
  async [AuthActionTypes.DECLINE_RINGING_CALL]({ commit }, chatId) {
    // optimistic update
    commit(AuthMutationTypes.REMOVE_RINGING_CALL, chatId);
    try {
      declineRingingCallAsync(chatId);
      // @ts-expect-error
    } catch (err: Error) {
      console.log(err.message);
    }
  },
};
