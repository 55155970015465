import { Attachment, AttachmentTypes } from "@/store/chats/types";

export const getOthersAttachments = (
  attachments: Attachment[] | undefined,
): Attachment[] | undefined => {
  if (!attachments) return;
  const othersAttachments = attachments.filter(
    (att) => att.type === AttachmentTypes.OTHERS,
  );
  if (othersAttachments.length === 0) return;
  return othersAttachments;
};
