import { AppMutationTypes } from "./mutationTypes";
import { AppMutationType } from "./mutationState";
import {
  BackgroundTypeObject,
  defaultProcessBackgroundColorObject,
  defaultSnackbar,
  LayoutBackgroundColorTypes,
} from "./state";

export const appMutations: AppMutationType = {
  [AppMutationTypes.SHOW_POPUP](
    state,
    {
      popupType,
      data,
      successBackgroundColorObject,
      processBackgroundColorObject = defaultProcessBackgroundColorObject,
    },
  ) {
    const failureBgColorObj: BackgroundTypeObject = {
      top: state.layout.backgroundColorType.top,
      bottom: state.layout.backgroundColorType.bottom,
    };

    const successBgColorObj: BackgroundTypeObject =
      successBackgroundColorObject ?? failureBgColorObj;
    const processBgColorObj: BackgroundTypeObject =
      processBackgroundColorObject ?? defaultProcessBackgroundColorObject;

    state.popup = {
      type: popupType,
      data: data || {},
      backgroundColor: {
        failure: failureBgColorObj,
        success: successBgColorObj,
        process: processBgColorObj,
      },
    };

    state.layout.backgroundColorType.top = processBgColorObj.top;
    state.layout.backgroundColorType.bottom = processBgColorObj.bottom;
  },
  [AppMutationTypes.HIDE_POPUP](state, isCancel) {
    const popupBgColorObj = state.popup.backgroundColor;
    state.popup = { ...state.popup, type: null, data: null };

    const appliedPopupBgColorObj: BackgroundTypeObject = isCancel
      ? popupBgColorObj.failure
      : popupBgColorObj.success;
    state.layout.backgroundColorType.top = appliedPopupBgColorObj.top;
    state.layout.backgroundColorType.bottom = appliedPopupBgColorObj.bottom;
  },
  [AppMutationTypes.SET_FULL_SIZE_IMAGE](state, src) {
    state.fullSizeImage = {
      src,
    };
  },
  [AppMutationTypes.SET_LAYOUT_TYPE](state, type) {
    state.layout.type = type;
  },
  [AppMutationTypes.SET_LAYOUT_WIDTH](state, width) {
    state.layout.width = width;
  },
  [AppMutationTypes.SET_LAYOUT_HEIGHT](state, height) {
    state.layout.height = height;
  },
  [AppMutationTypes.SET_LAYOUT](state, layout) {
    state.layout = { ...state.layout, ...layout };
  },
  [AppMutationTypes.SET_LANG](state, lang) {
    state.lang = lang;
  },
  [AppMutationTypes.SET_LAYOUT_BACKGROUND_COLOR_TOP](
    state,
    backgroundColor = LayoutBackgroundColorTypes.WHITE,
  ) {
    state.layout.backgroundColorType.top = backgroundColor;
  },
  [AppMutationTypes.SET_LAYOUT_BACKGROUND_COLOR_BOTTOM](
    state,
    backgroundColor = LayoutBackgroundColorTypes.WHITE,
  ) {
    state.layout.backgroundColorType.bottom = backgroundColor;
  },
  [AppMutationTypes.SET_LAYOUT_MESSAGE_INPUT_HEIGHT](state, height) {
    state.layout.messageInputHeight = height;
  },
  [AppMutationTypes.SET_NOTIFICATIONS_STATUS](state, status) {
    state.settings.notifications.status = status;
  },
  [AppMutationTypes.SET_PHONE_CONTACTS_STATUS](state, status) {
    state.settings.contacts.status = status;
  },
  [AppMutationTypes.SET_SNACKBAR_MOUNTED](state, object) {
    state.snackbar = { ...defaultSnackbar, ...object, isMounted: true };
  },
  [AppMutationTypes.SHOW_SNACKBAR](state) {
    if (state.snackbar.isShown) {
      return;
    }
    state.snackbar = { ...state.snackbar, isShown: true };
  },
  [AppMutationTypes.HIDE_SNACKBAR](state) {
    if (!state.snackbar.isShown) {
      return;
    }
    state.snackbar = { ...state.snackbar, isShown: false };
  },
  [AppMutationTypes.SET_SNACKBAR_UNMOUNTED](state) {
    state.snackbar = defaultSnackbar;
  },
  [AppMutationTypes.SET_IS_LOADING](state, isLoading) {
    state.isLoading = isLoading;
  },
  [AppMutationTypes.SET_ONBOARDING_ROUTES](state, routes) {
    state.onboardingRoutes = routes;
  },
  [AppMutationTypes.SET_APP_DETAILS](state, appDetails) {
    state.appDetails = appDetails;
  },
};
